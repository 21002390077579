import { useState, useContext, useEffect } from 'react';
import { v4 as uuid } from "uuid";
import { cloneDeep } from 'lodash';

import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as DashboardConstants from '../../constants/DashboardConstants';
import * as UserConstants from '../../constants/UserConstants';
import DashboardService from '../../services/DashboardService';
import { GlobalContext } from '../../context/GlobalContext';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import Footer from '../../components/Footer';
import DashboardContainer from '../../components/DashboardContainer';
import DashboardsGrid from '../../components/DashboardsGrid';
import DashboardElementModal from '../../components/DashboardElementModal';
import DashboardExportModal from '../../components/DashboardExportModal';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';

export default function DashboardsPage() {
   const {context, setContextValues} = useContext(GlobalContext);

   const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

   const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

   /* Role Access Code */

   const roles = context.user && context.user.roles ? context.user.roles : [];
   const [userCanCreate, setUserCanCreate] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.CREATE));
   const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.EDIT));
   const [userCanDelete, setUserCanDelete] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.DELETE));

   const [dashboards, setDashboards] = useState(context.organisation && context.organisation.dashboards ? context.organisation.dashboards : []);
   const [dashboardList, setDashboardList] = useState(context.organisation && context.organisation.dashboards ? context.organisation.dashboards.concat({_id: ""}) : [{_id: ""}]);

   const [selectedDashboard, setSelectedDashboard] = useState(null);
   const [dashboardPageIndex, setDashboardPageIndex] = useState(0);

   const [dashboardVersion, setDashboardVersion] = useState("0");

   const [canvasMode, setCanvasMode] = useState(DashboardConstants.CanvasMode.VIEW);
   const [pdfOrientation, setPdfOrientation] = useState(DashboardConstants.PageOrientation.LANDSCAPE);

   const doDashboardSelect = (dashboard) => {
      const versionId = uuid();
      setDashboardVersion(versionId);
      if (selectedDashboard === null || dashboard._id.toString() !== selectedDashboard._id.toString()) {
         setDashboardPageIndex(0);
      }
      setSelectedDashboard(dashboard);
   }

   const updateDashboardsContext = (newDashboards) => {
      let organisation = cloneDeep(context.organisation);
      organisation.dashboards = newDashboards;
      setContextValues([{ key: "organisation", value: organisation }]);
   }

   const updateDashboardsFromResponse = (newDashboards) => {
      let newDashboardList = cloneDeep(newDashboards);
      if (userCanCreate) {
         newDashboardList.push({_id: ""});
      }
      setDashboards(newDashboards);
      setDashboardList(newDashboardList);
      updateDashboardsContext(newDashboards);
   }

   const updateDashboardFromResponse = (dashboard, select) => {
      let newDashboards = cloneDeep(dashboards);
      let index = newDashboards.map(d => d._id).indexOf(dashboard._id);
      if (index > -1) {
         newDashboards[index] = dashboard;
      } else {
         newDashboards.push(dashboard);
      }
      let newDashboardList = cloneDeep(newDashboards);
      if (userCanCreate) {
         newDashboardList.push({_id: ""});
      }
      setDashboards(newDashboards);
      setDashboardList(newDashboardList);
      if (select) {
         doDashboardSelect(dashboard);
      }
      updateDashboardsContext(newDashboards);
   }

   const createDashboard = () => {
      const newTitle = `Dashboard #${dashboards.length + 1}`;
      DashboardService.createDashboard({ title: newTitle })
      .then(response => {
         updateDashboardFromResponse(response.data.dashboard, true);
      })
      .catch(err => {
         console.log(err);
         alert("Unable to create a dashboard at this time. Please try again later.");
      });
   }

   const deleteDashboard = (id) => {
      DashboardService.deleteDashboard(id)
      .then(response => {
         updateDashboardsFromResponse(response.data.dashboards);
      })
      .catch(err => {
         console.log(err);
         alert("Unable to delete dashboard at this time. Please try again later.");
      });
   }

   const updateDashboardTitle = (newTitle) => {
      DashboardService.updateDashboard({
         id: selectedDashboard._id,
         title: newTitle
      }).then(response => {
         updateDashboardFromResponse(response.data.dashboard, true);
      })
      .catch(err => {
         console.log(err);
         alert("Unable to update dashboard at this time. Please try again later.");
      });
   }

   const createPage = () => {
      let pages = cloneDeep(selectedDashboard.pages);
      pages.push({ elements: [] });
      const payload = {
         id: selectedDashboard._id,
         pages: pages
      };
      DashboardService.updateDashboard(payload)
      .then(response => {
         updateDashboardFromResponse(response.data.dashboard, true);
         setDashboardPageIndex(response.data.dashboard.pages.length - 1);
      })
      .catch(err => {
         console.log(err);
         alert("Unable to update dashboard at this time. Please try again later.");
      });
   }

   const deletePage = (index) => {
      if (selectedDashboard.pages.length === 1) {
         const id = selectedDashboard._id;
         setSelectedDashboard(null);
         deleteDashboard(id);
      } else {
         let pages = cloneDeep(selectedDashboard.pages);
         pages.splice(index, 1);
         const payload = {
            id: selectedDashboard._id,
            pages: pages
         };
         DashboardService.updateDashboard(payload)
         .then(response => {
            updateDashboardFromResponse(response.data.dashboard, true);
            setDashboardPageIndex(response.data.dashboard.pages.length - 1);
         })
         .catch(err => {
            console.log(err);
            alert("Unable to delete dashboard at this time. Please try again later.");
         });
      }
   }

   const createUpdateElement = (details) => {
      let index = dashboards.map(d => d._id).indexOf(details.boardId);
      if (index > -1) {
         let dashboard = cloneDeep(dashboards[index]);
         DashboardService.createUpdateElement({
            dashboard: dashboard,
            pageIndex: details.page,
            element: details.element
         }).then(response => {
            updateDashboardFromResponse(response.data.dashboard, true);
         })
         .catch(err => {
            console.log(err);
            alert("Unable to update dashboard at this time. Please try again later.");
         });
      }
   }

   const deleteElement = (details) => {
      let index = dashboards.map(d => d._id).indexOf(details.boardId);
      if (index > -1) {
         let dashboard = cloneDeep(dashboards[index]);
         DashboardService.deleteElement({
            dashboard: dashboard,
            pageIndex: details.page,
            elementId: details.id
         }).then(response => {
            updateDashboardFromResponse(response.data.dashboard, true);
         })
         .catch(err => {
            console.log(err);
            alert("Unable to delete dashboard element at this time. Please try again later.");
         });
      }
   }

   /* Dashboard Element Modal Functions */
   
   const [modalOpen, setModalOpen] = useState(false);
   const [modalParams, setModalParams] = useState({});

   const openElementModalCreate = (details) => {
      setModalParams({
         boardId: details.boardId,
         page: details.page,
         element: { id: "" },
         row: details.row,
         col: details.col,
         width: 1,
         height: 1
   });
      setModalOpen(true);
   }

   const openElementModalEdit = (details) => {
      const page = selectedDashboard.pages[details.page];
      let element = { id: "" };
      let index = page.elements.map(e => e._id).indexOf(details.id);
      if (index > -1) {
         element = cloneDeep(page.elements[index]);
         element.id = details.id;
      }
      setModalParams({
         boardId: details.boardId,
         page: details.page,
         element: element,
         row: details.row,
         col: details.col,
         width: details.width,
         height: details.height
      });
      setModalOpen(true);
   }

   const onModalDetailsSave = (details) => {
      createUpdateElement(details);
   }

   /* Dashboard Export Modal Functions */
   
   const [exportModalOpen, setExportModalOpen] = useState(false);

   const onModalExport = (details) => {
      if (details.action === DashboardConstants.ActionType.SET_CANVAS_MODE) {
         setCanvasMode(details.args.mode);
         if (details.args.mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
            setPdfOrientation(details.args.orientation);
         }
      }
      setExportModalOpen(false);
   }

   /* Trial Upgrade Modal Functions */

   const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

   const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

   const onTrialUpgradeClick = () => {
      setUpgradeModalOpen(true);
   }

   const onTrialUpgradeDone = () => {
      setIsTrial(false);
      setUpgradeModalOpen(false);
   }
   
   /* Interaction Functions */

   const onGridAction = (details) => {
      switch (details.action) {
         case DashboardConstants.ActionType.CREATE_DASHBOARD:
            createDashboard();
            break;
         case DashboardConstants.ActionType.SELECT_DASHBOARD:
            const index = dashboards.map(d => d._id).indexOf(details.args.id);
            if (index > -1) {
               doDashboardSelect(dashboards[index]);
            }
            break;
         case DashboardConstants.ActionType.DELETE_DASHBOARD:
            deleteDashboard(details.args.id);
            break;
         default:
               break;
      }
   }

   const onDashboardAction = (details) => {
      switch (details.action) {
         case DashboardConstants.ActionType.DESELECT_DASHBOARD:
            setSelectedDashboard(null);
            setDashboardPageIndex(0);
            break;
         case DashboardConstants.ActionType.UPDATE_DASHBOARD_TITLE:
            updateDashboardTitle(details.args.title);
            break;
         case DashboardConstants.ActionType.CREATE_PAGE:
            createPage();
            break;
         case DashboardConstants.ActionType.SELECT_PAGE:
            const versionId = uuid();
            setDashboardVersion(versionId);
            setDashboardPageIndex(details.args.index);
            break;
         case DashboardConstants.ActionType.DELETE_PAGE:
            deletePage(details.args.index);
            break;
         case DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_CREATE:
            openElementModalCreate(details.args);
            break;
         case DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_EDIT:
            openElementModalEdit(details.args);
            break;
         case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
            deleteElement(details.args);
            break;
         case DashboardConstants.ActionType.TEXT_UPDATED:
            createUpdateElement({
               boardId: details.args.boardId,
               page: details.args.page,
               element: {
                  _id: details.args.id,
                  text: details.args.text
               }
            });
            break;
         case DashboardConstants.ActionType.SET_CANVAS_MODE:
            setCanvasMode(details.args.mode);
            break;
         case DashboardConstants.ActionType.EXPORT_MODAL_OPEN:
            if (context.user && context.user.trialAccount === true) {
               alert("You cannot access this functionality as you are currently on a trial account.");
               return;
            }
            setExportModalOpen(true);
            break;
         default:
            break;
      }
   }

   /* Data Refresh Function */

   const [dataRefresh, setDataRefresh] = useState(false);
   
   useEffect(() => {
      if (dataRefresh) {
         const roles = context.user && context.user.roles ? context.user.roles : [];
         setUserCanCreate(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.CREATE));
         setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.EDIT));
         setUserCanDelete(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DASHBOARD, UserConstants.AccessTypes.DELETE));
         
         setDashboards(context.organisation.dashboards ? context.organisation.dashboards : []);
         setDashboardList(context.organisation.dashboards ? context.organisation.dashboards.concat({_id: ""}) : [{_id: ""}]);

         setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);

         setDataRefresh(false);
      }
   }, [dataRefresh, context, setUserCanCreate, setUserCanEdit, setUserCanDelete, 
      setDashboards, setDashboardList, setIsTrial, setDataRefresh]);

   /* Terms Agreement Functions */

   const [termsModalOpen, setTermsModalOpen] = useState(false);

   useEffect(() => {
      if (context.user && context.user.id) {
         if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
            setTermsModalOpen(false);
         } else {
            setTermsModalOpen(true);
         }
      } else {
         setTermsModalOpen(false);
      }
   }, [context, setTermsModalOpen]);

   return(
      <div className="w-full h-full flex">
         <KitSideNav page="Dashboards" onToggle={(value => setNavExpanded(value))}/>
         <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
            {isTrial ? (
               <KitTopBar 
                  onDataRefresh={() => setDataRefresh(true)} 
                  banner={GeneralConstants.TRIAL_BANNER}
                  onBannerClick={() => onTrialUpgradeClick()}
               />
            ) : (
               <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
            )}
            <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white">
               {selectedDashboard !== null ? (
                  <DashboardContainer
                     mode={canvasMode} 
                     dashboard={selectedDashboard} 
                     pageIndex={dashboardPageIndex} 
                     onDashboardAction={onDashboardAction}
                     accessTypes={{
                        create: userCanCreate,
                        edit: userCanEdit,
                        delete: userCanDelete
                     }}
                     version={dashboardVersion}
                     pdfOrientation={pdfOrientation}
                  />
               ) : (
                  <DashboardsGrid 
                     dashboardList={dashboardList}
                     onGridAction={onGridAction}
                     accessTypes={{
                        create: userCanCreate,
                        edit: userCanEdit,
                        delete: userCanDelete
                     }}
                     navExpanded={navExpanded}
                  />
               )}
               <Footer/>
            </div>
         </div>
         <DashboardElementModal
            params={modalParams}
            open={modalOpen}
            onSave={(details) => onModalDetailsSave(details)} 
            onClose={() => setModalOpen(false)}
         />
         <DashboardExportModal
            open={exportModalOpen}
            onExport={(details) => onModalExport(details)}
            onClose={() => setExportModalOpen(false)}
         />
         <TrialUpgradeModal
            open={upgradeModalOpen}
            onUpgrade={(_) => onTrialUpgradeDone()}
            onClose={() => setUpgradeModalOpen(false)}
         />
         <TermsAgreementModal
            open={termsModalOpen}
            onAgreement={() => setTermsModalOpen(false)}
         />
      </div>
   );
}