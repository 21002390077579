import { useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UiUtils from '../../utils/UiUtils';
import ResourceService from '../../services/ResourceService';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import { GlobalContext } from '../../context/GlobalContext';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import Button from '../../components/Button';
import FaqSet from '../../components/FaqSet';
import KitHomeResourceBlock from '../../components/KitHomeResourceBlock';
import Footer from '../../components/Footer';
import KitHomeNewFeature from '../../components/KitHomeNewFeature';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';

export default function KitHomePage() {
   const {context} = useContext(GlobalContext);

   const expandedSideNav = context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true;
   const [navExpanded, setNavExpanded] = useState(expandedSideNav);

   const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

   const navigate = useNavigate();

   /* Resources Functions */

   const [resources, setResources] = useState([]);

   const loadResources = useCallback(() => {
      ResourceService.getResources(false, UiConstants.RESOURCE_TYPE.HowTo)
      .then(response => {
         const allResources = response.data.resources;
         let resourceKeys = allResources.map(a => a.key);
         while (resourceKeys.length > 3) {
            const index = Math.floor(Math.random() * resourceKeys.length);
            resourceKeys.splice(index, 1);
         }
         const filterResources = resourceKeys.map(rk => allResources.find(a => a.key === rk));
         setResources(filterResources);
      })
      .catch(err => {
         console.log(err);
         setResources([]);
      })
   }, [setResources]);

   useEffect(() => {
      loadResources();
   }, [loadResources]);

   /* Trial Upgrade Modal Functions */

   const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

   const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

   const onTrialUpgradeClick = () => {
      setUpgradeModalOpen(true);
   }

   const onTrialUpgradeDone = () => {
      setIsTrial(false);
      setUpgradeModalOpen(false);
   }
   
   /* Interaction Functions */

   function onFeedbackClick() {
      //TODO: onFeedbackClick
   }

   function onResourcesMoreClick() {
      navigate("/kit/resources");
   }

   /* Data Refresh Function */

   const [dataRefresh, setDataRefresh] = useState(false);
   
   useEffect(() => {
      if (dataRefresh) {
         setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
         setDataRefresh(false);
      }
   }, [dataRefresh, context, setIsTrial, setDataRefresh]);

   /* Terms Agreement Functions */

   const [termsModalOpen, setTermsModalOpen] = useState(false);

   useEffect(() => {
      if (context.user && context.user.id) {
         if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
            setTermsModalOpen(false);
         } else {
            setTermsModalOpen(true);
         }
      } else {
         setTermsModalOpen(false);
      }
   }, [context, setTermsModalOpen]);

   return(
      <div className="w-full h-full flex">
         <KitSideNav page="Home" onToggle={(value) => setNavExpanded(value)}/>
         <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
            {isTrial ? (
               <KitTopBar 
                  banner={GeneralConstants.TRIAL_BANNER}
                  onBannerClick={() => onTrialUpgradeClick()}
               />
            ) : (
               <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
            )}
            <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white">
               <div className="grid grid-cols-3 gap-6 rounded-xl p-10 bg-light-green03 bg-kit-home-new-feature bg-right-bottom bg-no-repeat bg-contain">
                  <KitHomeNewFeature
                     icon="BuildingOfficeIcon"
                     title="Organisation"
                     buttonLabel="View Organisation"
                     link="/kit/organisation"
                  />
                  <KitHomeNewFeature
                     icon="BuildingStorefrontIcon"
                     title="Social Enterprise"
                     buttonLabel="View Enterprise"
                     link="/kit/enterprise"
                  />
                  <KitHomeNewFeature
                     icon="SparklesIcon"
                     title="Programs / Projects"
                     buttonLabel="View Progams / Projects"
                     link="/kit/progproject"
                  />
                  <KitHomeNewFeature
                     icon="LightBulbIcon"
                     title="Indicators"
                     buttonLabel="View Indicators"
                     link="/kit/indicators"
                  />
                  <KitHomeNewFeature
                     icon="TableCellsIcon"
                     title="Data Management"
                     buttonLabel="View Data Management"
                     link="/kit/datamanagement"
                  />
                  <KitHomeNewFeature
                     icon="SquaresPlusIcon"
                     title="Dashboards"
                     buttonLabel="View Dashboards"
                     link="/kit/dashboards"
                  />
               </div>
               {resources.length > 0 && (
               <div className="flex flex-col items-stretch gap-6 py-10">
                  <div className="flex justify-between items-center">
                     <h3 className="font-vg-medium text-[38px] leading-120 text-black">Resources</h3>
                     <Button size="small" variant="solid" label="More" onClick={onResourcesMoreClick}/>
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                     {resources.map(r => (
                        <KitHomeResourceBlock
                           key={r.key}
                           type={r.resourceType}
                           tags={[{ text: "How-to", color: "green" }]}
                           title={r.title}
                           text={r.lead}
                           link={`/resources/${r.key}`}
                        />
                     ))}
                  </div>
               </div>
               )}
               <div className="flex flex-col items-stretch gap-12 px-10 py-20 rounded-xl bg-grey03 bg-kit-home-faq bg-contain bg-right-bottom bg-no-repeat">
                  <h3 className="font-vg-medium text-black text-[38px] leading-120">Frequently asked questions</h3>
                  <div className="flex flex-col items-stretch gap-1">
                     <FaqSet/>
                  </div>
               </div>
               {1 === 0 ? (
                  <div className="flex items-center justify-between w-full my-5 gap-12 p-4 bg-grey rounded-xl">
                     <h6 className="font-vg-regular text-xl text-white">
                        Got ideas for improvement? We'd love to hear from you.
                     </h6>
                     <Button variant="solid" size="small" label="Share Feedback" onClick={onFeedbackClick}/>
                  </div>
               ) : (
                  <></>
               )}
               <Footer/>
            </div>
         </div>   
         <TrialUpgradeModal
            open={upgradeModalOpen}
            onUpgrade={(_) => onTrialUpgradeDone()}
            onClose={() => setUpgradeModalOpen(false)}
         />
         <TermsAgreementModal
            open={termsModalOpen}
            onAgreement={() => setTermsModalOpen(false)}
         />
      </div>
   );
}