import { useState, useContext, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';

import UiUtils from '../../utils/UiUtils';
import LibraryUtils from '../../utils/LibraryUtils';
import UnitUtils from '../../utils/UnitUtils';

import * as GeneralConstants from '../../constants/GeneralConstants';
import * as IndicatorConstants from '../../constants/IndicatorConstants';
import * as EntityConstants from '../../constants/EntityConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as DataConstants from '../../constants/DataConstants';
import * as UserConstants from '../../constants/UserConstants';

import { GlobalContext } from '../../context/GlobalContext';

import LibraryService from '../../services/LibraryService';
import PathwayService from '../../services/PathwayService';
import IndicatorService from '../../services/IndicatorService';
import CohortService from '../../services/CohortService';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import Footer from '../../components/Footer';
import IndicatorHomeContainer from '../../components/IndicatorHomeContainer';
import IndicatorTabContainer from '../../components/IndicatorTabContainer';
import Button from '../../components/Button';
import IndicatorModal from '../../components/IndicatorModal';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';

export default function IndicatorsPage() {
   const {context, setContextValues} = useContext(GlobalContext);

   const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

   const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

   const steps = IndicatorConstants.INDICATOR_STEPS;

   const [indicatorStep, setIndicatorStep] = useState(-1);
   const [indicatorSubStep, setIndicatorSubStep] = useState(-1);

   const [library, setLibrary] = useState(context.library ? context.library : { pathways: [], indicators: [], requiredData: [] });

   const [rightButton, setRightButton] = useState(UiConstants.ButtonType.NEXT);

   const [savingPathway, setSavingPathway] = useState(false);
   const [pathwayError, setPathwayError] = useState("");

   const selUnit = UnitUtils.getSelectedUnit(context);

   const [selectedUnit, setSelectedUnit] = useState(selUnit);

   /* Role Access Code */

   const roles = context.user && context.user.roles ? context.user.roles : [];
   const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.PATHWAY, UserConstants.AccessTypes.EDIT));

   /* Domain / Outcome Area / Pathway State Variables */

   const [domains, setDomains] = useState(context.library ? LibraryUtils.getDomainNeeds(context.library) : []);
   const [outcomeAreas, setOutcomeAreas] = useState([]);

   const [selectedNeed, setSelectedNeed] = useState("");
   const [selectedDomain, setSelectedDomain] = useState("");
   const [selectedArea, setSelectedArea] = useState("");

   const [pathwayId, setPathwayId] = useState("");
   const [pathwayName, setPathwayName] = useState("");
   const [selectedIndicators, setSelectedIndicators] = useState([]);

   const [pathwayRefresh, setPathwayRefresh] = useState(false);

   const [scrollToTop, setScrollToTop] = useState(false);

   const wrapperRef = useRef(null);

   /* Update Context / Entity Functions */

   const updateContextSelections = (type, id) => {
      let selections = context.selections ? cloneDeep(context.selections) : {};
      delete selections.enterprise;
      delete selections.program;
      delete selections.project;
      selections.type = type;
      if (id !== "") {
         switch (type) {
            case EntityConstants.EntityType.ENTERPRISE:
            case EntityConstants.EntityType.SOCIAL_ENTERPRISE:
               selections.enterprise = { id: id, edit: false };
               break;
            case EntityConstants.EntityType.PROGRAM:
               selections.program = { id: id, edit: false };
               break;
            case EntityConstants.EntityType.PROJECT:
               selections.project = { id: id, edit: false };
               break;
            default:
               break;
         }
      }
      setContextValues([{ key: "selections", value: selections }]);
   }

   const getReportingUnit = () => {
      let index = -1;
      let unit = null;
      switch (selectedUnit.type) {
         case EntityConstants.EntityType.ENTERPRISE:
         case EntityConstants.EntityType.SOCIAL_ENTERPRISE:
            if (context.organisation && context.organisation.enterprises) {
               index = context.organisation.enterprises.map(e => e._id).indexOf(selectedUnit.id);
               if (index > -1) {
                  unit = context.organisation.enterprises[index];
               }
            }
            break;
         case EntityConstants.EntityType.PROGRAM:
            if (context.organisation && context.organisation.programs) {
               index = context.organisation.programs.map(p => p._id).indexOf(selectedUnit.id);
               if (index > -1) {
                  unit = context.organisation.programs[index];
               }
            }
            break;
         case EntityConstants.EntityType.PROJECT:
            if (context.organisation && context.organisation.projects) {
               index = context.organisation.projects.map(p => p._id).indexOf(selectedUnit.id);
               if (index > -1) {
                  unit = context.organisation.projects[index];
               }
            }
            break;
         default:
            break;
      }
      return unit;
   } 

   const getUnitDetails = () => {
      let unit = getReportingUnit();
      if (unit) {
         return {
            pathways: unit.pathways,
            indicators: unit.indicators,
         };
      } else {
         return {};
      }
   }

   const updateEntity = (entity, reset) => {
      let index = -1;
      let update = false;
      let organisation = cloneDeep(context.organisation);
      switch (entity.entityType) {
         case EntityConstants.EntityType.ENTERPRISE:
         case EntityConstants.EntityType.SOCIAL_ENTERPRISE:
            index = organisation.enterprises.map(e => e._id).indexOf(entity._id);
            if (index > -1) {
               organisation.enterprises[index] = entity;
               update = true;
            }
            break;
         case EntityConstants.EntityType.PROGRAM:
            index = organisation.programs.map(e => e._id).indexOf(entity._id);
            if (index > -1) {
               organisation.programs[index] = entity;
               update = true;
            }
            break;
         case EntityConstants.EntityType.PROJECT:
            index = organisation.projects.map(e => e._id).indexOf(entity._id);
            if (index > -1) {
               organisation.projects[index] = entity;
               update = true;
            }
            break;
         default:
            break;
      }
      if (update) {
         setContextValues([{ key: "organisation", value: organisation }]);

         setSelectedUnit({...selectedUnit});

         if (reset) {
            setSelectedNeed("");
            setSelectedDomain("");
            setOutcomeAreas([]);
            setSelectedArea("");
            setPathwayId("");
            setPathwayName("");
            setSelectedIndicators([]);
            setIndicatorSubStep(-1);
            setIndicatorStep(-1);
            setRightButton(UiConstants.ButtonType.NEXT);
         }
      }
   };

   const getCustomIdTitle = () => {
      let customSuffix = selectedIndicators.filter(ind => ind._id.startsWith("custom") === true || (ind.indicatorDataKey && ind.indicatorDataKey.startsWith("custom"))).length + 1;
      const id = `custom${customSuffix}`;
      const title = `Custom Indicator ${customSuffix}`;
      return {
         id: id,
         title: title
      };
   };

   /* Database Calls */

   const deleteSavedPathway = (id, title) => {
      if (window.confirm(`Are you sure you want to delete the pathway '${title}' and archive any data for its indicators?`)) {
         if (selectedUnit.id !== "") {
            PathwayService.deletePathway(selectedUnit.id, id)
            .then(response => {
               updateEntity(response.data.entity, false);
               setPathwayRefresh(true);
            })
            .catch(err => {
               console.log(err);
               alert("Unable to delete pathway. Please try again later.");
            });
         }
      }
   }

   const deleteSavedPathwayIndicator = (pathwayId, indicatorId, title) => {
      if (window.confirm(`Are you sure you want to delete the indicator '${title}' from this pathway, and archive its data?`)) {
         if (selectedUnit.id !== "") {
            const unit = getReportingUnit();
            const indIndex = unit.indicators.map(i => i._id).indexOf(indicatorId);
            if (indIndex > -1) {
               const key = unit.indicators[indIndex].key;
               const payload = {
                  entityId: selectedUnit.id,
                  id: pathwayId,
                  indicatorEdits: {
                     remove: [key]
                  }
               };
               PathwayService.updatePathway(payload)
               .then(response => {
                  updateEntity(response.data.entity, false);
                  setPathwayRefresh(true);
               })
               .catch(err => {
                  console.log(err);
                  alert("Unable to delete indicator. Please try again later.");
               });
            }
         }
      }
   }

   /* Indicator Properties Modal Functions */
   
   const [modalOpen, setModalOpen] = useState(false);
   const [modalParams, setModalParams] = useState({
      type: IndicatorConstants.ActionType.NONE, 
      library: [], 
      pathwayName: "", 
      indicator: {}, 
      cohortOptions: [] 
   });

   const [cohortOptions, setCohortOptions] = useState([]); 

   const openIndicatorModal = (type, pathwayId, pathwayName, indicator) => {
      const unit = getReportingUnit();
      setModalParams({
         type: type,
         library: context.library ? context.library : library,
         unitRequiredData: unit.requiredData ? unit.requiredData : [],
         pathwayId: pathwayId,
         pathwayName: pathwayName,
         indicator: indicator,
         cohortOptions: context.cohortOptions ? context.cohortOptions : cohortOptions
      });
      setModalOpen(true);
   }

   const saveIndicatorDetails = (details) => {
      switch (details.type) {
         case IndicatorConstants.ActionType.EDIT_INDICATOR:
            const index = selectedIndicators.map(i => i._id).indexOf(details.indicator._id);
            if (index > -1) {
               let selIndicators = cloneDeep(selectedIndicators);
               selIndicators[index] = details.indicator;
               setPathwayName(details.pathwayName);
               setSelectedIndicators(selIndicators);
            }
            break;
      
         case IndicatorConstants.ActionType.EDIT_SAVED_INDICATOR:
            let payload = cloneDeep(details.indicator);
            payload.entityId = selectedUnit.id;
            payload.pathwayId = details.pathwayId;
            IndicatorService.updateIndicator(payload)
            .then(response => {
               updateEntity(response.data.entity, false);
               setPathwayRefresh(true);
            })
            .catch(err => {
               //TODO: Better error handling
               console.log(err);
            });
            break;
         
         case IndicatorConstants.ActionType.CREATE_INDICATOR:
            let selIndicators = cloneDeep(selectedIndicators);
            let ocAreas = cloneDeep(outcomeAreas);
            let pathwayName = details.pathwayName;
            let newStep = indicatorStep;
            let newSubstep = indicatorSubStep
            if (details.indicator.domain !== selectedDomain) {
               let domain = details.indicator.domain;
               setSelectedNeed(details.indicator.reportingNeed);
               setSelectedDomain(domain);
               ocAreas = LibraryUtils.getOutcomeAreas(library, domain);
               selIndicators = selIndicators.filter(ind => ind.domain === domain);
               if (domains.map(d => d.domain).indexOf(domain) === -1) {
                  let newDomains = cloneDeep(domains);
                  newDomains.push({ 
                     domain: domain,
                     reportingNeed: details.indicator.reportingNeed 
                  });
                  setDomains(newDomains);
               }
               newStep = 1;
               newSubstep = -1;
            }
            if (details.indicator.outcomeArea !== selectedArea) {
               let area = details.indicator.outcomeArea;
               setSelectedArea(area);
               selIndicators = selIndicators.filter(ind => ind.outcomeArea === area);
               if (ocAreas.indexOf(area) === -1) {
                  ocAreas.push(area);
               }
               pathwayName = area;
               newStep = 3;
               newSubstep = -1;
            }
            selIndicators.push(details.indicator);
            setPathwayName(pathwayName);
            setSelectedIndicators(selIndicators);
            setOutcomeAreas(ocAreas);
            setIndicatorStep(newStep);
            setIndicatorSubStep(newSubstep);
            setRightButton(newStep === 3 ? UiConstants.ButtonType.SAVE : UiConstants.ButtonType.NEXT);
            break;
         
         default:
            break;
      }
   }

   /* Interaction Functions */

   const onHomeAction = (details) => {
      let unitDetails;
      if (details.action) {
         switch (details.action) {
            case IndicatorConstants.ActionType.SELECT_UNIT:
               setSelectedUnit({ 
                  type: details.args.type, 
                  id: details.args.id,
                  name: UnitUtils.getSelectedUnitName(details.args.type, details.args.id) 
               });
               setPathwayRefresh(true);
               updateContextSelections(details.args.type, details.args.id);
               break;

            case IndicatorConstants.ActionType.CREATE_PATHWAY:
               if (selectedUnit.type !== EntityConstants.EntityType.NONE) {

                  setSelectedNeed("");
                  setSelectedDomain("");
                  setSelectedArea("");
                  setPathwayId("");
                  setPathwayName("");
                  setSelectedIndicators([]);

                  setIndicatorStep(0);
                  setIndicatorSubStep(-1);
                  setRightButton(UiConstants.ButtonType.NEXT);
               } else {
                  alert("To select/create indicators, you need to first select an existing Reporting Unit that you have created in Seedkit.");
               }
               break;

            case IndicatorConstants.ActionType.CREATE_PATHWAY_WITH_DOMAIN:
               if (selectedUnit.type !== EntityConstants.EntityType.NONE) {

                  setSelectedNeed(details.args.need);
                  setSelectedDomain(details.args.domain);
                  setOutcomeAreas(LibraryUtils.getOutcomeAreas(library, details.args.domain));
                  setSelectedArea("");
                  setPathwayId("");
                  setPathwayName("");
                  setSelectedIndicators([]);

                  setIndicatorStep(1);
                  setIndicatorSubStep(-1);
                  setRightButton(UiConstants.ButtonType.NEXT);
               }
               break;

            case IndicatorConstants.ActionType.EDIT_SAVED_INDICATOR:
               unitDetails = getUnitDetails();
               if (unitDetails.pathways && unitDetails.indicators) {
                  const indIndex = unitDetails.indicators.map(i => i._id).indexOf(details.args.indicatorId);
                  const pathIndex = unitDetails.pathways.map(i => i._id).indexOf(details.args.pathwayId);
                  if (indIndex > -1 && pathIndex > -1) {
                     openIndicatorModal(details.action, unitDetails.pathways[pathIndex]._id, unitDetails.pathways[pathIndex].title, unitDetails.indicators[indIndex]);
                  }
               }
               break;

            case IndicatorConstants.ActionType.DELETE_SAVED_INDICATOR:
               deleteSavedPathwayIndicator(details.args.pathwayId, details.args.indicatorId, details.args.title);
               break;

            case IndicatorConstants.ActionType.EDIT_PATHWAY:
               unitDetails = getUnitDetails();
               const savedPathIndex = unitDetails.pathways.map(i => i._id).indexOf(details.args.pathwayId);
               if (savedPathIndex > -1) {
                  const pathway = unitDetails.pathways[savedPathIndex];
                  const selIndicators = unitDetails.indicators.filter(ind1 => pathway.indicators.indexOf(ind1.key) > -1);

                  setSelectedNeed(pathway.reportingNeed);
                  setSelectedDomain(pathway.domain);
                  setOutcomeAreas(LibraryUtils.getOutcomeAreas(library, pathway.domain))
                  setSelectedArea(pathway.outcomeArea);
                  setPathwayId(pathway._id);
                  setPathwayName(pathway.title);
                  setSelectedIndicators(selIndicators);

                  setIndicatorStep(3);
                  setIndicatorSubStep(-1);
                  setRightButton(UiConstants.ButtonType.SAVE);
               }
               break;

            case IndicatorConstants.ActionType.DELETE_PATHWAY:
               deleteSavedPathway(details.args.pathwayId, details.args.title);
               break;
   
            case IndicatorConstants.ActionType.REFRESH_DONE_PATHWAY_TABLE:
               setPathwayRefresh(false);
               break;

            default:
               break;
         }
      }
   }

   const onTabAction = (details) => {
      let indIndex = -1;
      switch (details.action) {

         case IndicatorConstants.ActionType.CHANGE_STEP:
            const st = details.args.step;
            const subst = details.args.substep ? details.args.substep : (steps[st].substeps ? 0 : -1);
            setIndicatorStep(st);
            setIndicatorSubStep(subst);
            setRightButton(st === 3 ? UiConstants.ButtonType.SAVE : UiConstants.ButtonType.NEXT);
            break;

         case IndicatorConstants.ActionType.SELECT_DOMAIN:
            setSelectedNeed(details.args.need);
            setSelectedDomain(details.args.domain);
            setOutcomeAreas(LibraryUtils.getOutcomeAreas(library, details.args.domain));
            setSelectedArea("");
            setSelectedIndicators([]);
            break;

         case IndicatorConstants.ActionType.DESELECT_DOMAIN:
            setSelectedNeed("");
            setSelectedDomain("");
            setOutcomeAreas([]);
            setSelectedArea("");
            setSelectedIndicators([]);
            break;

         case IndicatorConstants.ActionType.SELECT_AREA:
            setSelectedArea(details.args);
            setPathwayName(details.args);
            const getDomainOptions = { withType: true, setArea: true, fullRequiredData: true };
            setSelectedIndicators(LibraryUtils.getDomainAreaIndicators(library, selectedDomain, details.args, getDomainOptions));
            break;

         case IndicatorConstants.ActionType.DESELECT_AREA:
            setSelectedArea("");
            setPathwayName("");
            setSelectedIndicators([]);
            break;

         case IndicatorConstants.ActionType.CHANGE_TYPE:
            setIndicatorSubStep(details.args);
            break;

         case IndicatorConstants.ActionType.COMPLETE_STEP:
            //TODO: Complete Step
            break;

         case IndicatorConstants.ActionType.ADD_INDICATOR:
            if (selectedIndicators.map(i => i.key).indexOf(details.args.indicator.key) === -1) {
               let selIndsAdd = cloneDeep(selectedIndicators); 
               selIndsAdd.push(details.args.indicator);
               setSelectedIndicators(selIndsAdd);
            } else {
               alert("This indicator has already been added to your pathway.");
            }
            break;

         case IndicatorConstants.ActionType.EDIT_INDICATOR:
            indIndex = selectedIndicators.map(i => i._id).indexOf(details.args);
            if (indIndex > -1) {
               openIndicatorModal(IndicatorConstants.ActionType.EDIT_INDICATOR, pathwayId, pathwayName, selectedIndicators[indIndex]);
            }
            break;

         case IndicatorConstants.ActionType.DUPLICATE_INDICATOR:
            //TODO: Duplicate indicator
            break;
   
         case IndicatorConstants.ActionType.DELETE_INDICATOR:
            indIndex = selectedIndicators.map(i => i._id).indexOf(details.args);
            if (indIndex > -1) {
               let selIndsDel = cloneDeep(selectedIndicators); 
               selIndsDel.splice(indIndex, 1);
               setSelectedIndicators(selIndsDel);
            }
            break;

         case IndicatorConstants.ActionType.CHANGE_NAME_PATHWAY:
            setPathwayName(details.args);
            break;

         case IndicatorConstants.ActionType.CREATE_INDICATOR:
            const idt = getCustomIdTitle();
            let customIndicatorA = {
               _id: idt.id,
               baseKey: idt.id,
               key: idt.id,
               title: idt.title,
               reportingNeed: "",
               domain: "",
               outcomeArea: "",
               indicatorType: "",
               description: "",
               frequency: "",
               format: IndicatorConstants.Format.NUMBER,
               indicatorDataKey: idt.id,
               usesCohort: false,
               requiredData: [idt.id]
            };
            openIndicatorModal(IndicatorConstants.ActionType.CREATE_INDICATOR, pathwayId, pathwayName, customIndicatorA);
            break;

         case IndicatorConstants.ActionType.CREATE_INDICATOR_WITH_TYPE:
            const idtB = getCustomIdTitle();
            let customIndicatorB = {
               _id: idtB.id,
               baseKey: idtB.id,
               key: idtB.id,
               title: details.args.title && details.args.title !== "" ? details.args.title : idtB.title,
               reportingNeed: details.args.reportingNeed,
               domain: details.args.domain,
               outcomeArea: details.args.outcomeArea,
               indicatorType: details.args.type,
               description: "",
               frequency: details.args.frequency ? details.args.frequency : "",
               format: IndicatorConstants.Format.NUMBER,
               indicatorDataKey: idtB.id,
               usesCohort: false,
               requiredData: [idtB.id]
            };
            openIndicatorModal(IndicatorConstants.ActionType.CREATE_INDICATOR, pathwayId, pathwayName, customIndicatorB);
            break;
   
         case IndicatorConstants.ActionType.PREVIOUS_STEP:
            onPreviousClick();
            break;

         case IndicatorConstants.ActionType.NEXT_STEP:
            onNextClick();
            break;

         case IndicatorConstants.ActionType.SAVE_PATHWAY:
            onSaveClick();
            break;

         default:
            break;
      }
   };

   const onPreviousClick = () => {
      let st = indicatorStep;
      let subst = indicatorSubStep;
      if (st > 0) {
         const substepCount = steps[st].substeps ? steps[st].substeps.length : 0;
         if (substepCount > 0) {
            if (subst > 0) {
               subst--;
            } else {
               st--;
               subst = steps[st].substeps.length - 1;
               setScrollToTop(true);
            }
         } else {
            st--;
            subst = steps[st].substeps.length - 1;
            setScrollToTop(true);
         }
         setIndicatorStep(st);
         setIndicatorSubStep(subst);
         setRightButton(st === 3 ? UiConstants.ButtonType.SAVE : UiConstants.ButtonType.NEXT);

      } else if (st === 0) {
         setIndicatorStep(-1);
         setIndicatorSubStep(-1);
         setRightButton(UiConstants.ButtonType.NEXT);
      }
   };

   const onNextClick = () => {
      let st = indicatorStep;
      let subst = indicatorSubStep;
      if (st < 3) {
         const substepCount = steps[st].substeps ? steps[st].substeps.length : 0;
         if (subst < substepCount - 1) {
            subst++;
         } else {
            st++;
            subst = steps[st].substeps.length > 0 ? 0 : -1;
            setScrollToTop(true);
         }
         setIndicatorStep(st);
         setIndicatorSubStep(subst);
         setRightButton(st === 3 ? UiConstants.ButtonType.SAVE : UiConstants.ButtonType.NEXT);
      }
   };

   const getIndicatorEdits = (indicators) => {
      let edits = {
         add: [],
         remove: []
      }
      const unit = getReportingUnit();
      const pathwayIndex = unit.pathways.map(p => p._id).indexOf(pathwayId);
      if (pathwayIndex > -1) {
         const pathIndKeys = unit.pathways[pathwayIndex].indicators;
         edits.add = indicators.filter(ind1 => pathIndKeys.indexOf(ind1.key) === -1);
         edits.remove = pathIndKeys.filter(ik => indicators.map(ind => ind.key).indexOf(ik) === -1);
      }
      return edits;
   }

   const onSaveClick = () => {
      if (selectedDomain === "") {
         alert("You cannot save this pathway as no domain is selected. Go back to Step 1 and choose one.");
         return;
      }
      if (selectedArea === "") {
         alert("You cannot save this pathway as no outcome area is selected. Go back to Step 2 and choose one.");
         return;
      }
      if (pathwayName === "") {
         alert("You cannot save a pathway without a name. Please enter one in the impact pathway field.");
         return;
      }
      if (pathwayId === "") {
         // New Pathway
         let indicators = cloneDeep(selectedIndicators);
         for (let i = 0; i < indicators.length; i++) {
            indicators[i].frequency = indicators[i].frequency ? (indicators[i].frequency === "" ? DataConstants.FrequencyType.MONTHLY : indicators[i].frequency) : DataConstants.FrequencyType.MONTHLY;
         }
         const payload = {
            entityId: selectedUnit.id,
            title: pathwayName,
            reportingNeed: selectedNeed,
            domain: selectedDomain,
            outcomeArea: selectedArea,
            indicators: indicators
         }
         setPathwayError("");
         setSavingPathway(true);
         PathwayService.savePathway(payload)
         .then(response => {
            setSavingPathway(false);
            updateEntity(response.data.entity, true);
         })
         .catch(err => {
            setPathwayError("Unable to save pathway. Please try again later.");
            setSavingPathway(false);
         });
      } else {
         // Updated Pathway
         const payload = {
            entityId: selectedUnit.id,
            id: pathwayId,
            title: pathwayName,
            indicatorEdits: getIndicatorEdits(selectedIndicators)
         };
         PathwayService.updatePathway(payload)
         .then(response => {
            setSavingPathway(false);
            updateEntity(response.data.entity, true);
            setPathwayRefresh(true);
         })
         .catch(err => {
            console.log(err);
            alert("Unable to update pathway name. Please try again later.");
            setPathwayRefresh(true);
         });
      }
   }

   /* useEffect Functions */

   useEffect(() => {
      let apiLibrary = context.library ? context.library : { indicators: [], requiredData: [] };
      let apiCohortOptions = context.cohortOptions ? context.cohortOptions : [];
      let promises = [];
      if (apiLibrary.indicators.length === 0) {
         promises.push(new Promise((resolve, reject) => {
            LibraryService.getFullLibrary()
            .then(response => {
               apiLibrary = response.data.library;
               resolve();
            })
            .catch(err => reject(err));
         }));
      }
      if (apiCohortOptions.length === 0) {
         promises.push(new Promise((resolve, reject) => {
            CohortService.getCohorts()
            .then(response => {
               apiCohortOptions = [UiConstants.EMPTY_OPTION];
               response.data.cohorts.forEach(c => {
                  apiCohortOptions.push({ value: c.key, label: c.title });
               });
               resolve();
            })
            .catch(err => reject(err));
         }));
      }
      if (promises.length > 0) {
         Promise.all(promises)
         .then(() => {
            setLibrary(apiLibrary);
            setDomains(LibraryUtils.getDomainNeeds(apiLibrary))
            setCohortOptions(apiCohortOptions);
            setContextValues([
               { key: "library", value: apiLibrary },
               { key: "cohortOptions", value: apiCohortOptions },
            ]);
         })
         .catch(err => {
            console.log(err);
               //TODO: Error handling
         });
      }
   }, [context, setContextValues]);

   useEffect(() => {
      if (scrollToTop) {
         if (wrapperRef && wrapperRef.current) {
            wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
         }
         setScrollToTop(false);
      }
   }, [scrollToTop]);

   /* Trial Upgrade Modal Functions */

   const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

   const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

   const onTrialUpgradeClick = () => {
      setUpgradeModalOpen(true);
   }

   const onTrialUpgradeDone = () => {
      setIsTrial(false);
      setUpgradeModalOpen(false);
   }
   
   /* Data Refresh Function */

   const [dataRefresh, setDataRefresh] = useState(false);
   
   useEffect(() => {
      if (dataRefresh) {
         setSelectedUnit(UnitUtils.getSelectedUnit(context));

         const roles = context.user && context.user.roles ? context.user.roles : [];
         setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.EDIT));

         setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);

         setDataRefresh(false);
      }
   }, [dataRefresh, context, setIsTrial, setDataRefresh]);

   /* Terms Agreement Functions */

   const [termsModalOpen, setTermsModalOpen] = useState(false);

   useEffect(() => {
      if (context.user && context.user.id) {
         if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
            setTermsModalOpen(false);
         } else {
            setTermsModalOpen(true);
         }
      } else {
         setTermsModalOpen(false);
      }
   }, [context, setTermsModalOpen]);

   return(
      <div className="w-full h-full flex">
         <KitSideNav page="Indicators" onToggle={(value) => setNavExpanded(value)}/>
         <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
            {isTrial ? (
               <KitTopBar 
                  onDataRefresh={() => setDataRefresh(true)} 
                  banner={GeneralConstants.TRIAL_BANNER}
                  onBannerClick={() => onTrialUpgradeClick()}
               />
            ) : (
               <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
            )}
            <div 
               ref={wrapperRef}
               className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white"
            >
               {indicatorStep === -1 && (
                  <IndicatorHomeContainer 
                     selectedUnit={selectedUnit} 
                     onHomeAction={onHomeAction}
                     pathwayRefresh={pathwayRefresh}
                     userCanEdit={userCanEdit}
                  />
               )}
               {indicatorStep >= 0 && userCanEdit && (
                  <>
                     <IndicatorTabContainer
                        index={indicatorStep}
                        subindex={indicatorSubStep}
                        steps={steps}
                        library={library}
                        domains={domains}
                        outcomeAreas={outcomeAreas}
                        selectedNeed={selectedNeed}
                        selectedDomain={selectedDomain}
                        selectedArea={selectedArea}
                        pathwayName={pathwayName}
                        selectedIndicators={selectedIndicators}
                        navButtons={{
                           rightButton: rightButton,
                           saving: savingPathway,
                           error: pathwayError
                        }}
                        onTabAction={(details) => onTabAction(details)}
                     />
                     <div className="flex justify-between items-center">
                        {savingPathway === true ? (
                           <h6 className="font-vg-regular text-base text-black leading-120" aria-live="polite">Saving Pathway...</h6>
                        ) : (
                           <>
                              <Button 
                                 variant="outline" 
                                 size="large" 
                                 label="Previous" 
                                 onClick={onPreviousClick}
                              />
                              <h6 className="font-vg-regular text-base text-red leading-120">{pathwayError}</h6>
                              {rightButton === UiConstants.ButtonType.NEXT && (
                              <Button 
                                 variant="solid" 
                                 size="large"
                                 label="Next" 
                                 onClick={onNextClick}
                              />
                              )}
                              {rightButton === UiConstants.ButtonType.SAVE && (
                              <Button 
                                 variant="solid" 
                                 size="large" 
                                 label="Save & Complete" 
                                 onClick={onSaveClick}
                              />
                              )}
                           </>
                        )}
                     </div>
                  </>
               )}
               <Footer/>
            </div>
         </div>
         <IndicatorModal 
            params={modalParams} 
            open={modalOpen}
            onSave={(details) => saveIndicatorDetails(details)} 
            onClose={() => setModalOpen(false)}
         />
         <TrialUpgradeModal
            open={upgradeModalOpen}
            onUpgrade={(_) => onTrialUpgradeDone()}
            onClose={() => setUpgradeModalOpen(false)}
         />
         <TermsAgreementModal
            open={termsModalOpen}
            onAgreement={() => setTermsModalOpen(false)}
         />
      </div>
   );
}