import { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { cloneDeep } from 'lodash';

import { GlobalContext } from '../context/GlobalContext';
import UserService from '../services/UserService';
import AuthService from '../services/AuthService';

import Button from './Button';

export default function TermsAgreementModal({ open, onAgreement }) {
   const {context, setContextValues} = useContext(GlobalContext);

   const navigate = useNavigate();

   const processingClass = 'col-span-2 font-vg-regular text-black text-base text-center mt-6';
   const errorClass = 'col-span-2 font-vg-regular text-red text-base text-center';
   const doneClass = 'col-span-2 font-vg-regular text-black text-base text-center';
   const [hideSubmit, setHideSubmit] = useState(false);
   const [statusClass, setStatusClass] = useState(doneClass);
   const [statusText, setStatusText] = useState('');

   const onAgree = (e) => {
      e.preventDefault();
      setHideSubmit(true);
      setStatusClass(processingClass);
      setStatusText("Submitting...");
      const details = {
         id: context.user.id,
         agreeToTerms: true
      }
      UserService.updateUser(details)
      .then(response => {
         setHideSubmit(false);
         setStatusClass(doneClass);
         setStatusText("");
         let contextValues = [];
         if (context.user) {
            let user = cloneDeep(context.user);
            user.agreeToTerms = true;
            contextValues.push({ key: "user", value: user });
         }
         if (contextValues.length > 0) {
            setContextValues(contextValues);
         }
         onAgreement();
      })
      .catch(error => {
         console.log('error', error);
         setHideSubmit(false);
         setStatusClass(errorClass);
         setStatusText(error.response && error.response.data ? error.response.data.message || error.message : error.message);
      });
   }

   const clearContext = () => {
      setContextValues([
         { key: "user", value: {} },
         { key: "organisation", value: {} },
         { key: "authToken", value: "" },
         { key: "selections", value: {} }
      ]);
   };

   const onComeBackLater = (e) => {
      e.preventDefault();
      setStatusClass(doneClass);
      setStatusText("");
      clearContext();
      AuthService.logout()
      .then(response => {
         navigate('/');
      })
      .catch(error => {
         console.log(error)
         navigate('/');
      });
   }

   return (
      <Transition show={open} as={Fragment}>
         <Dialog as="div" className="relative z-10" onClose={onComeBackLater}>
         <TransitionChild
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"                
            >
               <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
            </TransitionChild>
            <div className="fixed inset-0 z-10 overflow-y-auto">
               <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">\
                  <TransitionChild
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <DialogPanel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                        <div className="flex flex-col items-stretch gap-8">
                           <DialogTitle
                              as="h4"
                              className="font-vg-medium text-3.5xl text-black leading-110"
                           >
                              New Terms & Privacy Statement
                           </DialogTitle>
                           <p className="font-vg-book text-black text-base">
                              There are updates to Seedkit <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
                              Please read the updated terms and policies in full. Your continued use of the Seedkit site and services means you 
                              agree to the updated Terms of Use and that you acknowledge our updated Privacy Policy. Thank you for using Seedkit! 
                           </p>
                           <div className="grid grid-cols-2 gap-3">
                              <Button variant="solid" size="large" label="Yes, I Agree" className={hideSubmit ? "hidden" : "w-full"} onClick={onAgree}/>
                              <Button variant="outline" size="large" label="No, I'll Come Back Later"  className={hideSubmit ? "hidden" : "w-full"} onClick={onComeBackLater}/>
                              <p className={statusClass} aria-live="polite">{statusText}</p>
                           </div>
                        </div>
                     </DialogPanel>
                  </TransitionChild>
               </div>
            </div>
         </Dialog>
      </Transition>
   );
}